import { FunctionComponent, useEffect, useState } from "react";
import { Button, ButtonType, CustomSelect, FilterableTable, Modal, Option, OptionSelect, Radio, SelectType, TextInput } from "_components";
import { getRoles, createUser, UserResponseType, RoleResponseType, DealershipResponseType, deleteUser, updateUser, getDealerships, getUsersById } from "_api";
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useLoading, useModal } from "_hooks";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { atomUser ,atomUserRole } from "_atoms";
import { AxiosError, AxiosResponse } from "axios";
import './manage-users.scss';

// Create user form type.
type CreateUserFormValues = {
  first_name: string;
  last_name: string;
  email: string;
  dealers: Array<OptionSelect>;
  role: string;
  dealership?: DealershipResponseType[],
};

// Update user form type.
type UpdateUserFormValues = {
  updateFirst_name: string;
  updateLast_name: string;
  updateEmail: string;
  updateDealership: Array<OptionSelect>;
  updateRole: OptionSelect;
};

// Filterable table columns.
const columns = ['first_name', 'last_name', 'email', 'role', 'dealers'];

const ManageUsers: FunctionComponent = () => {
  const [users, setUsers] = useState<Array<UserResponseType>>([]);
  const [roles, setRoles] = useState<Array<RoleResponseType>>([]);
  const [roleOptions, setRoleOptions] = useState<Array<Option>>([]);
  const [dealerships, setDealerships] = useState<Array<DealershipResponseType>>([]);
  const [dealershipOptions, setDealershipOptions] = useState<Array<OptionSelect>>([]);
  const [roleSelectOptions, setRoleSelectOptions] = useState<Array<OptionSelect>>([]);
  const [userToDelete, setUserToDelete] = useState<UserResponseType>();
  const [editableColumnId, setEditableColumnId] = useState<string | null>(null);
  const { setModal } = useModal();
  const { setLoading } = useLoading();
  const { t } = useTranslation();
  const [user , setUser] = useRecoilState(atomUser);

  const userRole = useRecoilValue(atomUserRole);

  const isAgent =  userRole?.name === "Agent";
  const isDealer = userRole?.name === "Dealer";
  const isManagerADV = userRole?.name === "Manager_ADV";
  const isADV = userRole?.name === "ADV";
  const isManagerCommercial = userRole?.name === "Manager_commercial";
  const isCommercial = userRole?.name === "Commercial";
  const isDAF = userRole?.name === "DAF";
  const isAdmin = userRole?.name === "Admin-trigano";

  const filterDealersUsers = (users: UserResponseType[]) => {
    if (isDealer || isManagerADV || isADV || isManagerCommercial || isCommercial || isDAF) {
      return users.filter(
        user =>
        user.role?.name !== 'Admin-trigano' &&
        user.role?.name !== 'Agent' &&
        user.role?.name !== 'Agent-Limite' &&
        user.role?.name !== 'User-trigano'
      );
    }
    return users;
  };

  // Create user validator.
  const CreateUserValidationSchema = Yup.object().shape({
    first_name: Yup.string().required(t('form_required_field')),
    last_name: Yup.string().required(t('form_required_field')),
    email: Yup.string().email(t('form_invalid_format')).required(t('form_required_field')),
    dealership: Yup.array().required(t('form_required_field')),
    role: Yup.string().required()
  });

  // Update user validator.
  const UpdateUserValidationSchema = Yup.object().shape({
    updateFirst_name: Yup.string().required(t('form_required_field')),
    updateLast_name: Yup.string().required(t('form_required_field')),
    updateEmail: Yup.string().email(t('form_invalid_format')).required(t('form_required_field')),
    updateDealership: Yup.array().of(Yup.object()).required(t('form_required_field')),
    updateRole: Yup.object().required('form_required_field'),
  });

  // Create user form control.
  const {
    reset,
    control: createUserControl,
    handleSubmit: createUserHandleSubmit,
    formState: createUserFormState,
    reset: resetUserFormState
  } = useForm<CreateUserFormValues>({
    resolver: yupResolver(CreateUserValidationSchema),
    mode: 'onChange',
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      dealership: [],
      role: undefined,
    }
  });

  const {
    control: updateUserControl,
    setValue: updateUserSetValue,
    getValues: updateUserGetValues,

  } = useForm<UpdateUserFormValues>({
    resolver: yupResolver(UpdateUserValidationSchema),
    mode: 'onChange',
    defaultValues: {
      updateFirst_name: '',
      updateLast_name: '',
      updateEmail: '',
      updateDealership: undefined,
      updateRole: undefined,
    }
  });

  const userLogged = useRecoilValue(atomUser);

  // Load data from API (users/roles/dealerships).
  const initData = async () => {
    setLoading(true);
    if (userLogged && userLogged.id) {
      getUsersById(userLogged.id).then((usersResult: AxiosResponse<UserResponseType[]>) => {
        const users = usersResult.data.map((_result: UserResponseType) => {
          return {
            id: _result.id,
            first_name: _result.first_name,
            last_name: _result.last_name,
            email: _result.email,
            role: _result.role,
            brands : _result.brands, 
            dealers: _result.dealers,
          }
        });
        setUsers(users);
        getDealerships().then((dealershipsResult) => {
          setDealerships(dealershipsResult.data);
          getRoles().then((rolesResult) => {
            setRoles(rolesResult.data);
            setLoading(false);
          }, () => {
            toast.error(t('toast_get_data_error'));
            setLoading(false);
          });

        }, () => {
          toast.error(t('toast_get_data_error'));
          setLoading(false);
        });
      }, () => {
        toast.error(t('toast_get_data_error'));
        setLoading(false);
      });
    }
  }

  // Build role Radio options (used on create user form) & Role Select options (used on update user form).
  useEffect(() => {
    const roleOptions = roles.map((role) => {
      return {
        id: role.name,
        label: <span className="o-info" aria-label={role.description}>{role.label}</span>,
        value: role.name
      }
    });
    setRoleOptions(roleOptions);
    const roleSelectOptions = roles.map((role) => {
      return {
        label: role.label || '',
        name: role.name,
        value: role.name
      }
    });
    setRoleSelectOptions(roleSelectOptions);
  }, [roles]);

  const buildDealershipOptions = () => {
    if (dealerships && dealerships.length) {
      const dealershipOptions = dealerships.map((dealership) => {
        return {
          label: dealership.name,
          name: dealership.name,
          value: dealership.id
        }
      });
      setDealershipOptions(dealershipOptions);
    }
  }

  // Build Dealership Select options (used on create/update user form).
  useEffect(() => {
    buildDealershipOptions();
  }, [dealerships]);

  // Create user (API).
  const onCreateUserSubmit = (data: CreateUserFormValues) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dealersIds = data.dealership ? data.dealership.map((dealer: any) => {
      return dealer.value;
    }) : [];
    const user = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      role: data.role,
      dealers: dealersIds,
      language: '',
      country: '',
    }
    setLoading(true);
    createUser(user).then((result) => {
      const userToadd = {
        id: result.data.id,
        first_name: result.data.first_name,
        last_name: result.data.last_name,
        email: result.data.email,
        role: roles.find((_role) => _role.name === user.role),
        dealers: data.dealership,
        language: user.language,
        brands : result.data.brands, 
        country: "FR",
      }
      setUsers([...users, userToadd]);
      resetUserFormState();
      reset();
      toast.success(t('toast_create_user_success'));
    }).catch((error: AxiosError) => {
      if (error && error.response && error.response.status === 409) {
        toast.error(t('toast_user_already_exists'));
      }
      else {
        toast.error(t('toast_create_user_error'));
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  // Load user data on update form.
  const prepareUpdate = (data: UserResponseType) => {
    updateUserSetValue("updateFirst_name", data.first_name);
    updateUserSetValue("updateLast_name", data.last_name);
    updateUserSetValue("updateEmail", data.email);
    if (data.role) {
      updateUserSetValue("updateRole", {
        label: data.role.name,
        name: data.role.name,
        value: data.role.name
      });
    }
    if (data.dealers) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateUserSetValue("updateDealership", data.dealers.map((dealership: any) => {
        return {
          label: dealership.name,
          value: dealership.id.toString(),
          name: dealership.name
        }
      }));
    }
    setEditableColumnId(data.id);
  }

  // Update user (API).
  const onUpdateUser = (user: UserResponseType) => {
    const values = updateUserGetValues();
    if (values.updateDealership.length && values.updateRole && values.updateFirst_name && values.updateLast_name && values.updateEmail) {
      const data = {
        id: user.id,
        first_name: values.updateFirst_name,
        last_name: values.updateLast_name,
        email: values.updateEmail,
        dealers: values.updateDealership.map((value) => value.value.toString()),
        role: values.updateRole.value
      };
      setLoading(true);
      updateUser(user.id, data).then(() => {
        toast.success(t('toast_update_success'));
        setUsers(users.map((_user) => {
          if (_user.id === user.id) {
            const newDealers = values.updateDealership.map((_value) => {
              return {
                brands: [],
                code_m3: "",
                id: _value.value,
                name: _value.name
              }
            })
            return { ..._user, first_name: values.updateFirst_name, last_name: values.updateLast_name, dealers: newDealers, role: values.updateRole }
          }
          else return _user;
        }));
        
        if (userLogged?.id === user.id) {
        setUser((prev : any) => {
          return {
            ...prev,
            completeName: data.first_name + " " + data.last_name
          }
        })}
        setEditableColumnId(null);
      }).catch(() => {
        toast.error(t('toast_update_error'));
      }).finally(() => {
        setLoading(false);
      });
    }
  }

  // Prepare delete (select user to delete) & show modal.
  const prepareDelete = (user: UserResponseType) => {
    setUserToDelete(user);
    setModal(true);
  }

  // Delete user (API).
  const onDeleteUser = () => {
    if (userToDelete) {
      setLoading(true);
      deleteUser(userToDelete).then(() => {
        toast.success(t('toast_delete_success'));
        setUsers(users.filter((_user) => _user.id !== userToDelete.id));
      }).catch((error) => {
        if (error.response && error.response.status === 412) {
          toast.error(t('toast_delete_error_pim'));
        } else if (error.response && error.response.status === 413) {
          toast.error(t('toast_delete_error_azure'));
        } else {
          toast.error(t('users_delete_error'));
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  // On page enter: load API data.
  useEffect(() => {
    initData();
  }, [user]);

  const filteredUsersForDealers = filterDealersUsers(users);

  // Render filterable table rows.
  const renderRows = (data: Array<UserResponseType>) => {
    return data.map((_data) => {
      return <tr key={_data.id}>
        <td className="capitalize">
          {editableColumnId?.toString() === _data.id ? (
            <TextInput name="updateFirst_name" type="text" control={updateUserControl} />
          ) : (<>{_data.first_name}</>)}
        </td>
        <td className="capitalize">
          {editableColumnId?.toString() === _data.id ? (
            <TextInput name="updateLast_name" type="text" control={updateUserControl} />
          ) : (<>{_data.last_name}</>)}
        </td>
        <td>
          { // eslint-disable-next-line no-constant-condition
            editableColumnId?.toString() === _data.id && false ? (
              <TextInput name="updateEmail" type="email" control={updateUserControl} />
            ) : (<>{_data.email}</>)}
        </td>
        <td>
          {editableColumnId === _data.id ? (
            <CustomSelect name="updateRole" optionsList={roleSelectOptions} required type={SelectType.REGULAR} isMulti={false} control={updateUserControl} />
          ) : (<>{_data.role && _data.role.label}</>)}
        </td>
        <td>
          {editableColumnId?.toString() === _data.id ? (
            <CustomSelect
              name="updateDealership"
              optionsList={dealershipOptions}
              required
              type={SelectType.REGULAR}
              control={updateUserControl}
              isMulti={true}
            />
          ) : (<>{(_data.dealers && _data.dealers.length && _data.dealers.length > 1) ? _data.dealers.length + ' concessions' : _data.dealers && _data.dealers.length ? _data.dealers[0].name : ''} </>)}
        </td>
        <td className="u-center">
        <span className="filterable-table__actions-container">
            {editableColumnId?.toString() === _data.id ? (
              <>
                <Button type={ButtonType.SAVE} onClick={() => onUpdateUser(_data)} />
                <Button type={ButtonType.CANCELINLINE} onClick={() => setEditableColumnId(null)} />
              </>
            ) : (
              <Button
                type={ButtonType.EDIT}
                onClick={() => prepareUpdate(_data)}
                disabled={!isAgent && !isAdmin && !isDealer}
              />
            )}
          </span>
        </td>
        <td className="u-center">
          <Button
            type={ButtonType.DELETE}
            onClick={() => prepareDelete(_data)}
            disabled={!isAgent && !isAdmin && !isDealer}
          />
        </td>
      </tr>
    });
  }

  return (
    <>
      <Modal content={false} onValidate={onDeleteUser}><div className="users__modal-text">{t('users_confirm_delete')}</div></Modal>
      <div className="users">
        <h2 className="users__title">{t('users_add_user')}</h2>
        <form className="users__form" onSubmit={createUserHandleSubmit(onCreateUserSubmit)}>
          <div className="users__form-sections-wrapper">
            <div className="users__form-section">
              <div className="o-form-group o-form-group--required">
                <label className="o-form-group__label" htmlFor="firstname">{t('users_firstname')}</label>
                <div className="o-form-group__input-container">
                  <TextInput name="first_name" type="text" control={createUserControl} />
                </div>
              </div>

              <div className="o-form-group o-form-group--required">
                <label className="o-form-group__label" htmlFor="lastname">{t('users_lastname')}</label>
                <div className="o-form-group__input-container">
                  <TextInput name="last_name" type="text" control={createUserControl} />
                </div>
              </div>

              <div className="o-form-group o-form-group--required">
                <label className="o-form-group__label" htmlFor="name">{t('users_email')}</label>
                <div className="o-form-group__input-container">
                  <TextInput name="email" type="email" control={createUserControl} />
                </div>
              </div>

              <div className="o-form-group o-form-group--required">
                <label className="o-form-group__label" htmlFor="name">{t('users_dealership')}</label>
                <div className="o-form-group__input-container">
                  <CustomSelect name="dealership" optionsList={dealershipOptions} required type={SelectType.REGULAR} control={createUserControl} isMulti={true} />
                </div>
              </div>
            </div>
            <div className="users__form-section o-form-group--required">
              <div className="o-form-group">
                <label className="o-form-group__label" htmlFor="name">{t('users_role')}</label>
                <div className="o-form-group__input-container">
                  <Radio name="role" options={roleOptions} control={createUserControl} />
                </div>
              </div>
            </div>
          </div>
          <div className="users__form-validation">
            <Button type={ButtonType.PRIMARYSMALL} text="Ajouter" disabled={!createUserFormState.isValid || (!isAgent && !isAdmin && !isDealer)}/>
            <Button type={ButtonType.CANCEL} text="Annuler" onClick={(e) => {
              e.preventDefault();
              reset();
            }} />
          </div>

        </form>

        <h2 className="users__title">{t('users_user_list')}</h2>
        {isManagerADV || isADV || isManagerCommercial || isCommercial || isDAF ? null : (
        isDealer ? (
          filteredUsersForDealers && filteredUsersForDealers.length ? (
            <FilterableTable
              columns={columns}
              renderRows={renderRows}
              data={filteredUsersForDealers}
              addEditColumn={true}
              addDeleteColumn={true}
              currentEditableId={editableColumnId?.toString()}
            />
          ) : null
        ) : (
          users && users.length > 0 ? (
            <FilterableTable
              columns={columns}
              renderRows={renderRows}
              data={users}
              addEditColumn={true}
              addDeleteColumn={true}
              currentEditableId={editableColumnId?.toString()}
            />
          ) : null
        )
      )}
    </div>
    </>
  );
}

export default ManageUsers;
